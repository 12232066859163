<template>
<div class="tw-flex tw-w-full tw-flex-col" :style="{backgroundImage:`url(${bg})`}" >
  <home-navbar />
  <section class="section-one tw-mt-10">
    <div class="lg:tw-w-6/12 tw-w-full tw-flex tw-flex-col ipadsc">
         <h6 class="section-one-header">Let’s Get Better Together</h6>
        <h6 class="section-one-desc tw-py-10">
          Promoting social cohesion, integration, and diversity amongst teenagers in the community,
          bridging the gap between the Portsmouth local community
          and the international basketball nation.

        </h6>
      <div class="tw-w-full tw-flex-row tw-items-center tw-hidden lg:tw-flex ipad-hid">
         <v-btn text class="get-started tw-mr-10" @click="$router.push({name:'Contact'})">Let's talk</v-btn>
        <v-btn text class="let-talk" @click="donate">Donate with <v-icon class="tw-ml-2">mdi-heart</v-icon></v-btn>
      </div>
    </div>
    <div class="lg:tw-w-5/12 tw-w-full ipadsc tw-flex-col">
        <img :src="sectionOneImg" alt="image" class="section-one-img">
      <div class="tw-w-full tw-flex-row tw-flex-wrap tw-items-center tw-justify-center lg:tw-hidden tw-flex ipad-show tw-pt-10">
        <v-btn text class="get-started tw-mr-3 md:tw-mr-10" @click="$router.push({name:'Contact'})">Let's talk</v-btn>
        <v-btn text class="let-talk" @click="donate">Donate with <v-icon class="tw-ml-2">mdi-heart</v-icon></v-btn>
      </div>
    </div>
  </section>
  <section class="section-two section-one tw-flex tw-items-center">
    <div  class="tw-w-full lg:tw-w-6/12 lg:tw-flex tw-hidden tw-justify-end ">
      <img :src="sectionTwoImg" alt="players"  class="section-two-img"/>
    </div>
    <div  class="tw-w-full lg:tw-w-6/12 tw-flex tw-justify-start lg:tw-pl-24 tw-flex-col ipadsc tw-pt-20 md:tw-pt-0">
       <h6 class="section-two-header tw-pt-10">The story we are telling about the journey we are making.</h6>
      <div class="short-line"/>
      <img :src="sectionTwoImg" alt="players"  class="section-two-img tw-flex lg:tw-hidden"/>
      <h6 class="section-two-desc">
        We are on a journey to discover, engage and nurture young talented Portsmouth kids in basketball,
        helping to shape their ideologies, exposing them to educational,
        sporting and employment opportunities for the future.
      </h6>
      <p class="section-two-desc tw-pt-5">Join us on this journey and make this story more beautiful</p>
      <v-btn text class="volunteer-btn tw-mt-5" @click="$router.push({name:'AddVolunteer'})">Volunteer Today</v-btn>
    </div>
  </section>
  <section class="section tw-flex-col tw-mt-20">
    <h6 class="section-two-header tw-pt-10">Our Key Objectives</h6>
    <div class="short-line"/>
   <div class="tw-w-full tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-items-start">
    <div class="lg:tw-w-5/12 tw-w-full">
      <p class="section-two-desc tw-pt-5">
        B4B annual event, gears to sustainable urban advancement with great attention on the socio-economic
        legacy created by her events. We have built an ecosystem of lasting social sporting activities focused
        mainly on people development and empowerment.
      </p>
      <p class="section-two-desc tw-pt-5">Therefore we believe in constant evolution of some
        (if not all) activities as we expect  increased frequency in people participation (applicants and volunteers),
        space (sport center) and provisions for successful events in the coming years.</p>
    </div>
     <div class="lg:tw-w-6/12 tw-w-full tw-flex lg:tw-flex-row  tw-flex-col tw-flex-wrap tw-pt-10 lg:tw-pt-0">
       <v-col sm="12" lg="6" class="tw-mb-16" v-for="(goal, index) in goals" :key="index">
         <div class=" tw-flex tw-flex-row tw-items-center">
           <img  :src="goal.icon" class="card-img tw-mr-8" alt="icon"/> <h6 class="card-title">{{goal.title}}</h6>
         </div>
         <p class="card-desc tw-mt-5">{{goal.desc}}</p>
       </v-col>
     </div>
   </div>
<!--    <div class="tw-w-full tw-flex-row tw-items-center tw-hidden lg:tw-flex tw-mt-0 ">-->
<!--      <v-btn text class="volunteer-btn tw-mr-10">Volunteer Today</v-btn>-->

<!--    </div>-->
  </section>
  <section class=" tw-hidden tw-items-center tw-flex-col" style="align-items: center; min-height: 50vh">
    <h6 class="section-two-header tw-mb-20">What people are saying</h6>
    <splide :options="options" class="tw-w-full tw-flex-col">
      <splide-slide v-for="(item, index) in items" :key="index"
                    class="tw-flex  justify-space-between  tw-flex-col slide-card tw-p-8">
          <p class="text-content lg:tw-mx-4">{{ item.content }}</p>
        <div class="tw-flex tw-flex-row  tw-items-start lg:tw-mx-4" >
          <img :src="item.image" alt="user" class="tw-mr-4" />
          <div class="tw-flex tw-flex-col">
            <p class="user-name">{{ item.name }}</p>
            <p class="text-content">{{ item.title }}</p>
          </div>
        </div>

      </splide-slide>
    </splide>
  </section>
  <section class="partners-section tw-flex-col tw-flex">
    <h6 class="section-two-header tw-mb-20">Organisations supporting us </h6>
    <div class="tw-flex tw-flex-row tw-w-full tw-justify-center partners-scroll">
      <img :src="partner" v-for="(partner, index) in partners" :key="index" alt="partners" class="tw-mr-10" />
    </div>

    <div class="tw-flex-col tw-pt-16 tw-justify-center tw-flex subscribe-div" >
      <h6 class="subscribe-header tw-pb-10">
        Subscribe to our newsletter
      </h6>
      <div class="subscribe tw-w-full lg:tw-flex-row tw-flex-col  tw-flex tw-items-center">
        <v-col sm="12" lg="3" >
          <v-text-field solo hide-details placeholder="First Name" v-model="subscribe.fullName" ></v-text-field>
        </v-col>
        <v-col sm="12" lg="4">
          <v-text-field solo hide-details placeholder="Email" v-model="subscribe.email"></v-text-field>
        </v-col>
        <v-col sm="12" lg="3">
          <v-btn text height="55" class="volunteer-btn" :loading="subscribeLoading" @click="handleSubscribe">Subscribe</v-btn>
        </v-col>
      </div>
    </div>

  </section>
  <Footer/>
</div>
</template>

<script>
import HomeNavbar from "@/components/Navbar";
import safety from "@/assets/safety.svg"
import player from "@/assets/player.svg"
import reader from "@/assets/reader.svg"
import userReview from "@/assets/userreview.png"
import readerTwo from "@/assets/reader-two.svg"
import sectionOneImg from "@/assets/sectionone.png"
import sectionTwoImg from "@/assets/sectiontwoimg.png"
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import aax from "@/assets/aax.svg"
import cubase from "@/assets/cubase.svg"
import digg from "@/assets/digg.svg"
import ladspa from "@/assets/ladspa.svg"
import square from "@/assets/square.svg"
import youtube from "@/assets/youtube.svg"
import racketx from "@/assets/rackext.svg"
import bg from "@/assets/bg.svg"
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import Footer from "@/components/Footer";
import { subscribe } from "@/services/api/APIService";
export default {
  name: "IndexPage",
  components: {Footer, HomeNavbar, Splide,SplideSlide},
  data(){
    return{
      sectionOneImg,
      sectionTwoImg,
      bg,
      goals:[

        {
          icon:  reader,
          title : "Collaboration",
          desc: "Social cohesion, integration, and inclusion amongst young participating teenagers and their parents."
        },
        {
          icon: player,
          title : "Opportunities",
          desc: "Expose our young ones to educational (scholarships), and sporting opportunities"
        },
        {
          icon: safety,
          title : "Safer Community",
          desc: "Engaging the young ones, and giving them hope for tomorrow"
        },
        {
          icon:readerTwo,
          title : "Discipline",
          desc: "To instill self discipline and social coordination"
        },

      ],
      options: {
        rewind: true,
        gap   : '2rem',
        autoplay : false,
        perPage : 3,
        type: 'loop',
        arrows: true,
        pagination:false,
        breakpoints: {
          1023: {
            perPage: 1,
            arrows: false,
          },
          1024:{
            perPage: 2,
            arrows: false,
          }
        },
      },
      items:[
        {
          content: "Subscribe to our monthly newsletter to stay connected to " +
              "our activities, get insights and updates on what's happening in our community!",
          name:"Peter Parker",
          title: "Photography",
          image: userReview
        },
        {
          content: "Subscribe to our monthly newsletter to stay connected to " +
              "our activities, get insights and updates on what's happening in our community!",
          name:"Peter Parker",
          title: "Photography",
          image: userReview
        },
        {
          content: "Subscribe to our monthly newsletter to stay connected to " +
              "our activities, get insights and updates on what's happening in our community!",
          name:"Peter Parker",
          title: "Photography",
          image: userReview
        },
        {
          content: "Subscribe to our monthly newsletter to stay connected to " +
              "our activities, get insights and updates on what's happening in our community!",
          name:"Peter Parker",
          title: "Photography",
          image: userReview
        },
      ],
      partners:[ladspa,digg,racketx,square,youtube,aax,cubase],
      subscribe:{},
      subscribeLoading: false
    }
  },
  methods:{
    handleSubscribe(){
      this.subscribeLoading = true
      if (this.subscribe.fullName && this.subscribe.email){
        subscribe(this.subscribe).then(() =>{
          this.subscribeLoading = false
          this.subscribe={}
          this.$displaySnackbar({
            message: "Thank you for subscribing, an email have been sent your Inbox",
            success: true,
          });
        })
          .catch(() => {
            this.subscribeLoading = false;
            this.$displaySnackbar({
              message: "Please Something went wrong try again",
              success: false,
            });

          });
      }
      else {
        this.subscribeLoading =false
        this.$displaySnackbar({
          message: "Full Name and Email required",
          success: false,
        });
      }
    },
    donate(){
      window.open("https://www.crowdfunder.co.uk/basketball4buckland","_blank")
    }
  }
}
</script>

<style scoped lang="scss">

.section-one{
  display: flex;
  min-height: 100vh;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 10rem;

  @media screen and (max-width: 1024px){
    padding: 5rem;
    flex-direction: column;
  }

  @media screen and (max-width: 768px){
    padding: 4rem 1.5rem;
    flex-direction: column;
  }
}

.section{
  display: flex;
  min-height: 100vh;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 10rem;

  @media screen and (max-width: 1024px){
    padding: 5rem;
    flex-direction: column;
  }

  @media screen and (max-width: 768px){
    padding: 4rem 1.5rem;
    flex-direction: column;
  }
}
.section-one-img{
  @media screen and (min-width: 1900px) {
    height: 769px;
  }
  height: 569px;
  @media screen and (max-width: 768px) {
    height: 370px !important;
    width: 100%;
  }
}

.partners-section{
  display: flex;
  min-height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 0 10rem 0 10rem;

  @media screen and (max-width: 1024px){
    padding: 5rem;
    flex-direction: column;
  }

  @media screen and (max-width: 768px){
    padding: 4rem 1.5rem;
    flex-direction: column;
  }
}

.section-one-header{
  font-family: 'Roboto',sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 66px;
  color: #000000;

  @media screen and (max-width: 1024px) {
    font-size: 24px;
    line-height: 28px;
    padding-top: 2rem;
  }
}

.section-one-desc{
  font-family: 'Roboto',sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.1em;
  color: rgba(0, 0, 0, 0.5);
  width: 80%;
  @media screen and (max-width: 1024px){
    width: 100%;
  }
}

.get-started{
  width: 230px;
  height: 48px;
  background: #F66B05;
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
  text-transform: none;
  padding: 1.5rem !important;
  @media screen and (max-width: 1024px) {
    width: 150px;
    height: 48px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }
}

.let-talk{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 230px;
  height: 48px;
  background: #FFFFFF;
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #F66B05;
  text-transform: none;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  padding: 1.5rem !important;

  @media screen and (max-width: 1024px) {
    width: 150px;
    height: 48px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }


}

.ipadsc{
  @media screen and (max-width: 1024px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.ipad-hid{
  @media screen and (max-width: 1024px) {
    display: none;
  }
}
.ipad-show{
  @media screen and (max-width: 1024px) {
    display: flex;
  }
}

.section-two{

}

.section-two-img{
  height: 552px;
}

.section-two-header{
  font-family: 'Roboto',sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: 0.05em;
  color: #000000;

  @media screen and (max-width: 1024px) {
    font-size: 24px;
    line-height: 28px;
  }
}

.section-two-desc{
  font-family: 'Roboto',sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.1em;
  color: #000000;

  @media screen and (max-width: 1024px) {
    padding-top: 2rem;
  }
}

.short-line{
  width: 100px;
  height: 5px;
  background: #F66B05;
  border-radius: 10px;
  margin: 2rem 0;
}
.volunteer-btn{
  width: 250px;
  height: 68px;
  background: #F66B05;
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
  text-transform: none;
  padding: 1.5rem !important;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.card-img{
  height: 60px;
  width: 60px;
}

.card-title{
  font-family: 'Roboto',sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  letter-spacing: 0.05em;
  color: rgba(0, 0, 0, 0.7);
  @media screen and (max-width: 1024px) {
    font-size: 20px;
    line-height: 23px;
  }
}

.card-desc{
  font-family: 'Roboto',sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.1em;
}

.slide-card{
  width: 550px;
  height: 250px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  @media screen and (max-width: 1024px) {
   height: auto;

  }
}
.text-content{
  font-family: 'Roboto',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.05em;
  color: rgba(0, 0, 0, 0.4);
  min-height: 120px;
  @media screen and (max-width: 1024px) {
    height: 150px;
  }
}

.user-name{
  font-family: 'Roboto',sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.05em;
  color: #000000;
}

::v-deep .splide__arrow--prev {
  left: -4rem;
}

::v-deep .splide__arrow--next {
  right: -4rem;
}

::v-deep .splide__arrow {
  background-color: #F66B05;
}

.subscribe-div{
  width: 70%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
.subscribe{
  @media screen and (max-width: 1024px) {
    background: #FFFFFF;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 3rem;

    @media screen and (max-width: 768px) {
      padding: 1rem;
    }
  }
}

.subscribe-header{
  font-family: 'Roboto',sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: 0.05em;
  color: rgba(0, 0, 0, 0.6);
}

.partners-scroll{
  @media  screen and (max-width: 768px) {
    overflow-x: scroll;
    overflow-y: hidden;
  }
}
</style>